import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    SelectInput,
    TextInput,
    Filter,
    NumberField,
    ReferenceField,
    ReferenceInput,
    Pagination,
    ArrayField,
    SingleFieldList,
    BulkDeleteButton,
    CardActions,
    CreateButton,
} from 'react-admin';
import StatusTextField from '../field/StatusTextField';
import { MyActiveSites, MyPrimarySites, MyPromoSites, MyFrontPageSites } from '../field/Site';
import BulkActiveButton from '../button/BulkActiveButton';
import BulkInActiveButton from '../button/BulkInActiveButton';
import BulkArchiveButton from '../button/BulkArchiveButton';
import ResyncButton from '../button/AdsResync';

const AdFilter = ( { permissions, ...props }) => (
        <Filter {...props}>
            <TextInput label="Termék keresése" source="title" alwaysOn />
    { permissions === 'admin' && <ReferenceInput source="user" reference="users" label="Hirdető" perPage={100} alwaysOn ><SelectInput source="name" /></ReferenceInput> }
            <SelectInput label="Státusz" source="status" choices={[
                   { id: '0', name: 'elfogadásra vár' },
                   { id: '1', name: 'aktív' },
                   { id: '2', name: 'inaktív' },
                   { id: '3', name: 'archív' },
                   { id: '4', name: 'időzített' },
            ]} alwaysOn />
    { permissions === 'admin' && 
            <ReferenceInput source="category_id" reference="categories_list" label="Kategória" perPage={100} alwaysOn >
                <SelectInput source="name" />
            </ReferenceInput>
    }
        </Filter>
);
//    siteads.featured

//a = []
//for (i=2016; i<=2019; i++) { a.push({id: i, value: i}) }
//

const AdStatus = ({ record }) => {
    var statusK, color, command;
    if ( parseInt(record.status) === 0 ){
        statusK = "elfogadásra vár";
        color = 'error';
    }
    else if ( parseInt(record.status) === 1 ) {
        statusK = "aktív";
        color = 'primary';
    }
    else if ( parseInt(record.status) === 2 ) {
        statusK = "inaktív";
        color = "secondary";
    }
    else if ( parseInt(record.status) === 3 ) {
        statusK = "archív";
        color = "inherit";
    }
    else if ( parseInt(record.status) === 4 ) {
        statusK = "időzített";
        color = "error";
    }
    command = <StatusTextField source="status" statusK={statusK} color={color} />;
    return (
        command
    );
};

const AdPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

const AdBulkActionButtons = ({ permissions, ...props }) => (
        <Fragment>
            <BulkDeleteButton {...props} />
    { permissions === 'admin' && <BulkActiveButton {...props} /> }
    { permissions === 'admin' && <BulkInActiveButton {...props} /> }
    { permissions === 'admin' && <BulkArchiveButton {...props} /> }
        </Fragment>
);

const AdActions = ({
        permissions,
        bulkActions,
        basePath,
        currentSort,
        displayedFilters,
        exporter,
        filters,
        filterValues,
        onUnselectItems,
        resource,
        selectedIds,
        showFilter,
        total
}) => (
        <CardActions>
            {bulkActions && React.cloneElement(bulkActions, {
                            basePath,
                            filterValues,
                            resource,
                            selectedIds,
                            onUnselectItems,
                        })}
            {filters && React.cloneElement(filters, {
                            resource,
                            showFilter,
                            displayedFilters,
                            filterValues,
                            context: 'button',
                        }) }
            <CreateButton basePath={basePath} />
    { permissions === 'admin' && <ResyncButton resource="ads-resync" redirect="ads" /> }
        </CardActions>
);

export const AdList = ({ permissions, ...props }) => (
        <List {...props} filters={<AdFilter permissions={permissions} />} actions={<AdActions permissions={permissions} />} bulkActionButtons={<AdBulkActionButtons permissions={permissions} />} sort={{ field: "status", order: "ASC" }} title="Hirdetések" pagination={<AdPagination />} exporter={false} >
            <Datagrid rowClick="show">
                <TextField label="Hirdetés azonosító" source="id" />
                { permissions === 'admin' ? <ReferenceField source="user" reference="users" linkType={false} label="Hirdető" ><TextField source="name" /></ReferenceField> :  null}
                { permissions === 'admin' ? <NumberField label="Hirdető azonosító" source="user" /> : null}
                <TextField source="title" label="Termék"/>
                {<AdStatus label="Státusz" source='status' />}
                <ArrayField source="siteads" label="Aktiv oldalak">
                    <SingleFieldList linkType={false}>
                        <MyActiveSites />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="siteads" label="Elsődleges">
                    <SingleFieldList linkType={false}>
                        <MyPrimarySites />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="siteads" label="Promóció">
                    <SingleFieldList linkType={false}>
                        <MyPromoSites />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="siteads" label="Főoldalon">
                    <SingleFieldList linkType={false}>
                        <MyFrontPageSites />
                    </SingleFieldList>
                </ArrayField>
                <EditButton label="" />
            </Datagrid>
        </List>
);
