import React from 'react';
import MyAvatar from '../field/customLetterAvatar';
import MyTextField from '../field/MyTextField';
import { SitesData, SiteIDData } from '../constants';

export const SiteName = ({ record = {} }) => {
    const site = SitesData[parseInt(record.site_id)];

    return <MyAvatar source='site_id' sitename={site.name} bgcolor={site.bgcolor} />;
};

export const SiteID2Site = ({ record }) => {
        const site = SiteIDData[parseInt(record.site_id)];

        return <MyTextField source="site_id" data={site.name} />;

};

export const MyActiveSites = ({ record }) => {
    var myStatus = record.status;
    return (
        <>
            { myStatus === 1 && <SiteName record={record} /> }
        </>
    );
};

export const MyPromoSites = ({ record }) => {
    return (
        <>
            { record.is_promo === 1 && <SiteName record={record} /> }
        </>
    );
};

export const MyPrimarySites = ({ record }) => {
    return (
        <>
            { record.is_primary === 1 && <SiteName record={record} /> }
        </>
    );
};

export const MyFrontPageSites = ({ record }) => {
    return (
        <>
            { record.is_frontpage === 1 && <SiteName record={record} /> }
        </>
    );
};
